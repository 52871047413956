import { computed, onMounted, readonly, ref } from 'vue';
import { useLanguageStore, useTitleDetailsStore } from '@/helpers/composables/useStores';
import { useUserAgent } from '@/helpers/composables/useUserAgent';

import { toValue, type MaybeRefOrGetter } from '@vueuse/core';

import type { UrlHelper } from '@/helpers/clickout-helper';
import { ClickoutUrl, ClassicUrl } from '@/helpers/clickout-helper';
import type { TitleOfferFragment } from '@/components/buybox/graphql/fragments/Offer.fragment';
import { getVm } from '../vm-helper';

const sessionStorage = process.client && window != undefined ? window.sessionStorage : null;

// prettier-ignore
const ArbitrationCountries = [
	'AR', 'AT', 'AU', 'BE', 'BR', 'CA', 'CH', 'CL', 'CO', 'CZ', 'DE', 'DK', 'ES', 'FI', 'FR', 'GB', 'IE', 'IL', 'IN', 'IT', 'MX', 'NL', 'NO', 'NZ', 'PH', 'PL', 'PT', 'SA', 'SE', 'US', 'ZA',
] as const;

const EMPTY = '$' as const;

/*
**Note aboute utm_network & gclid**

`utm_network` and `gclid` are directly related to each other and `gclid` needs to be present when `utm_network` is.
Snowplow depends on `gclid` to derive the proper `utm_network`.
More info: https://docs.snowplow.io/docs/enriching-your-data/available-enrichments/campaign-attribution-enrichment/
*/
const utmParams = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_network', 'gclid'] as const;

type UtmArbitrationValues = { [param in (typeof utmParams)[number]]: string };
interface ArbitrationValues extends UtmArbitrationValues {
	titleid: string;
	device: 'm' | 'd';
}

const _isArbitrating = ref(false);
export const isArbitrating = readonly(_isArbitrating);

const ArbitrageProviders = [
	350, // Apple TV+
	2, // iTunes
	15, // Hulu
];

export function useArbitrage() {
	//TITLE_DETAIL_REDESIGN_NEW_BUYBOX
	const route = getVm()?._route;
	//TITLE_DETAIL_REDESIGN_NEW_BUYBOX
	const { country } = useLanguageStore();
	const { titleContext } = useTitleDetailsStore();

	onMounted(() => {
		if (!ArbitrationCountries.includes(country.value)) return;

		if (_isArbitrating.value || process.server) return;

		type UTMQueryParams = [string, string | null][];
		const utmValues = utmParams
			.map(param => [param, route.query[param] as string | null])
			.filter(([, value]) => value != null) as UTMQueryParams;
		if (utmValues.length === 0) return;

		utmValues.forEach(([key, value]) => sessionStorage?.setItem(key, value!));

		_isArbitrating.value = true;
	});

	const clearArbitration = () => {
		utmParams.forEach(param => sessionStorage?.removeItem(param));

		_isArbitrating.value = false;
	};

	const { deviceInfo, deviceType } = useUserAgent();

	const arbitration = computed<ArbitrationValues | null>(() => {
		if (!isArbitrating.value) return null;

		const {
			utm_medium = EMPTY,
			utm_content = EMPTY,
			utm_campaign = EMPTY,
			utm_source = EMPTY,
			utm_network = EMPTY,
			gclid = EMPTY,
		} = getSessionUtmQueryParams()!;
		const titleid: string = titleContext.value?.jwEntityId ?? EMPTY;
		const device = deviceType.value === 'mobile' ? 'm' : 'd';

		return { utm_medium, titleid, device, utm_content, utm_campaign, utm_source, utm_network, gclid };
	});

	/** Sets the 'uct_ct' query on the Offer URL if needed. */
	function withArbitrageTracking(offer: MaybeRefOrGetter<TitleOfferFragment | null>) {
		const offerValue = toValue(offer);
		const packageId = offerValue?.package.packageId ?? 0;
		const handler = getArbitrationHandler(packageId);

		if (ArbitrageProviders.includes(packageId) && arbitration.value && handler && offerValue) {
			return handler.plugin(offerValue, arbitration.value, country.value);
		}

		return null;
	}

	function trackTagEvent(offer: MaybeRefOrGetter<TitleOfferFragment | null>) {
		if (!window || !window.dataLayer) return;
		if (!isArbitrating.value) return;

		const packageId = toValue(offer)?.package.packageId ?? 0;
		const handler = getArbitrationHandler(packageId);

		if (ArbitrageProviders.includes(packageId)) {
			window.dataLayer.push({
				event: 'interaction',
				// event category
				target: 'clickout',
				// event label
				'target-properties': handler?.clearName ?? '',
				device: deviceInfo.value,
			});
		}
	}

	return {
		isArbitrating,
		clearArbitration,
		withArbitrageTracking,
		trackTagEvent,
	};
}

/** Get the `utm_*` query params as a URLSearchParam object. `null` if not Arbitrating. */
export function getUtmSearchParams() {
	if (isArbitrating.value == null) return null;

	const sessionParams = getSessionUtmQueryParams();
	if (sessionParams == null) return null;

	const paramString = utmParams
		.filter(param => sessionParams[param] != null)
		.map(param => `${param}=${sessionParams[param]}`)
		.join('&');

	return new URLSearchParams(paramString);
}

/** Get the `utm_*` query params from the current session. `null` if not Arbitrating. */
export function getSessionUtmQueryParams() {
	if (isArbitrating.value == null) return null;

	const utm = utmParams.map(param => sessionStorage?.getItem(param) ?? undefined);
	if (utm.every(param => param == null)) return null;

	const [utm_campaign, utm_content, utm_medium, utm_source, utm_network, gclid] = utm;

	return { utm_medium, utm_content, utm_campaign, utm_source, utm_network, gclid };
}

function getArbitrationHandler(packageId: number): ArbitrationHandler | null {
	switch (packageId) {
		case 2:
		case 350:
			return appleTvPlus;
		case 15:
			return hulu;
		default:
			return null;
	}
}

interface ArbitrationHandler {
	clearName: string;
	plugin: (offer: TitleOfferFragment, values: ArbitrationValues, country: string) => UrlHelper;
}

const appleTvPlus: ArbitrationHandler = {
	clearName: 'Apple TV Plus',
	plugin: (offer, { utm_medium, titleid, device, utm_content, utm_campaign }) =>
		new ClassicUrl(offer.standardWebURL)
			.set('ct', `${utm_medium}-${titleid}-${device}-${utm_content}-${utm_campaign}`)
			.set('itsct', utm_campaign === 'dsa_at' ? 'justwatch_tv_10' : 'justwatch_tv_3'),
};

const hulu: ArbitrationHandler = {
	clearName: 'Hulu',
	plugin: (offer, { utm_medium, titleid, utm_content, utm_campaign }, country) =>
		new ClickoutUrl(offer)
			.set('uct_country', country)
			.setOnOffer('sub2', utm_medium)
			.setOnOffer('sub3', utm_content)
			.setOnOffer('sub4', titleid)
			.setOnOffer('sub5', utm_campaign),
};
