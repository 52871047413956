import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
export type RegisterSessionStartMutationVariables = Types.Exact<{
	input: Types.RegisterSessionStartInput;
}>;

export type RegisterSessionStartMutation = {
	__typename?: 'Mutation';
	registerSessionStart: { __typename?: 'RegisterPayload'; loginId: string };
};

export const RegisterSessionStartDocument = gql`
	mutation RegisterSessionStart($input: RegisterSessionStartInput!) {
		registerSessionStart(input: $input) {
			loginId
		}
	}
`;
