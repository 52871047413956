import { getVm } from '@/helpers/vm-helper';
import { ExperimentTrackEventOptions } from '@/components/experiments/Core/types';
import NewBuyBoxTVShowExpV3, {
	NewBuyBoxTVShowExpV3Name,
	NewBuyBoxTVShowExpV3Variants,
} from '@/components/experiments/NewBuyBoxTVShowExpV3';
import { useArbitrage } from '@/helpers/composables/useArbitrage';

export const trackTitleDetailRedesignNewBuyBoxExp = (
	trackingOptions: ExperimentTrackEventOptions<NewBuyBoxTVShowExpV3Variants>,
	lastRoute = false
) => {
	const { isArbitrating } = useArbitrage();
	const vm = getVm();
	const activeVariant =
		vm?.$store.getters['experiment/activeVariantsWithControlGroup'][NewBuyBoxTVShowExpV3Name] ?? null;
	const isUserLoggedIN = vm?.$store.getters['user/isLoggedIn'] ?? false;
	const lastRouteName = vm?.$store.state.routing.lastRoute?.name;
	const isShowPage = (lastRoute ? lastRouteName : vm?.$route.name) === 'app.detail.show';
	if (activeVariant && !isUserLoggedIN && isShowPage && !isArbitrating.value) {
		NewBuyBoxTVShowExpV3.trackEvent({ property: 'show', ...trackingOptions });
	}
};

export const loadMicrosoftClarity = () => {
	if (document.getElementById('clarity-script')) {
		return;
	}
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.defer = true;
	script.async = true;
	script.id = 'clarity-script';
	script.innerHTML = `(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "ffbppisl6e");`;

	document.head.appendChild(script);
};
