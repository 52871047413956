import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';

// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP_V3:control
// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP_V3:variant_1
// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP_V3:variant_2
// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP_V3:variant_3
// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP_V3:variant_4
// ?preferred_variant=NEW_BUYBOX_TV_SHOW_EXP_V3:variant_5

// ?remove_preferred_experiment=NEW_BUYBOX_TV_SHOW_EXP_V3
// ?utm_source=google&utm_medium=cpc&utm_campaign=watch_apple_title&utm_content=tl&utm_id=at&monetization_types=free

export const NewBuyBoxTVShowExpV3Name = 'NEW_BUYBOX_TV_SHOW_EXP_V3' as const;

export type NewBuyBoxTVShowExpV3Variants = ToVariants<typeof NewBuyBoxTVShowExpV3Variants>;

export const NewBuyBoxTVShowExpV3Variants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
	VARIANT_2: 'variant_2',
	VARIANT_3: 'variant_3',
	VARIANT_4: 'variant_4',
	VARIANT_5: 'variant_5',
} as const;

export default generate(NewBuyBoxTVShowExpV3Name, NewBuyBoxTVShowExpV3Variants);
